import {
  ChangeEvent,
  FormEvent,
  Fragment,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import M from "materialize-css";
import { useRef } from "react";
import { StatusCodes } from "http-status-codes";

import { IAttandencesAPI } from "..";
import { api } from "../../../services/api";
import Alert from "../../../shared/Alert";
import { formatCPF } from "@brazilian-utils/brazilian-utils";
import { Checkbox } from "@mui/material";

type AtendimentoProps = {
  id?: number;
  afterClose: () => void;
  loadAttencedences: () => void;
  agendaEvento: IAttandencesAPI[];
};

const Atendimento = ({
  id,
  afterClose,
  agendaEvento,
  loadAttencedences,
}: AtendimentoProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const attachmentInputRef = useRef<HTMLInputElement>(null);
  const [localizationCode, setLocalizationCode] = useState<string>("");
  const [stage, setStage] = useState<number>(0);
  const [observation, setObservation] = useState<string>("");
  const [files, setFiles] = useState<any[]>([]);
  const [sigiloso, setSigiloso] = useState<boolean>(true);

  const handleCloseModal = () => {
    if (modalRef?.current) {
      M.Modal.getInstance(modalRef.current).close();
      setLocalizationCode("");
      setStage(0);
    }
  };

  const handleSubmitLocalizationCode = async (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();
    const { status } = await api.get("/attendance/localizer", {
      params: {
        localizador: localizationCode,
        ateCodigo: agendaEvento[0].ateCodigo,
      },
    });

    if (status === StatusCodes.OK) {
      await Alert.fire({
        title: "Sucesso",
      });

      setStage(1);
    }
  };

  const handleAttachmentClick = async (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();
    attachmentInputRef.current?.click();
  };

  const handleAttachmentChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files?.[0];
    if (file) {
      setFiles([...files, file]);
    }
    if (attachmentInputRef.current) {
      attachmentInputRef.current.value = "";
    }
  };

  const handleAttachmentRemove = async (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();
    const targetElement = e.target as HTMLElement;
    const index = parseInt(targetElement.dataset.index!);
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      window.confirm(
        "Deseja confirmar esse atendimento?\nAinda será possível anexar comprovantes (ASO)."
      )
    ) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = async () => {
        const atendimento = {
          comprovante: {
            descricao: files[0].name,
            arquivo: reader.result!.toString().split("base64,")[1],
            Sigiloso: Number(sigiloso),
          },
          ateCodigo: agendaEvento[0].ateCodigo,
          observacao: observation,
        };

        try {
          const { status } = await api.post(
            `/attendance/attendance`,
            atendimento
          );

          if (status === StatusCodes.OK) {
            handleCloseModal();
            setStage(0);
            loadAttencedences();
          }
        } catch (error) {}
      };

      reader.onerror = function (error) {};
    }
  };

  useEffect(() => {
    if (agendaEvento.length > 0 && id) {
      M.Modal.init(modalRef.current!, {
        onCloseEnd: (el) => {
          afterClose();
          setFiles([]);
        },
      });
      const modalInstance = M.Modal.getInstance(modalRef.current!);
      if (
        agendaEvento.find(
          (item) => item.ateStatus === "S" || item.ateStatus === "A"
        )
      )
        setStage(1);
      modalInstance.open();
    }
  }, [afterClose, agendaEvento, id]);

  if (agendaEvento.length === 0 || !id) {
    return <></>;
  }

  return (
    <div id={`modal-agenda-${id}`} className="modal" ref={modalRef}>
      <div className="modal-content">
        <h4>Atendimento</h4>
        {agendaEvento.map((evento) => (
          <Fragment key={evento.ateCodigo}>
            {evento.funcionario.map((fun) => (
              <Fragment key={fun.fPlanId}>
                <b>
                  {fun.funNome} - {fun.funcao.fcoNome}
                </b>
                <br />
                <b>RG: </b>
                {fun.funRG}
                <br />
                <b>CPF: </b>
                {formatCPF(fun.funCPF)}
                <br />
                <b>Horário: </b> {evento.ateHora}
              </Fragment>
            ))}
            <form
              method="POST"
              id="form_atendimento"
              encType="multipart/form-data"
              style={{ display: stage === 1 ? "block" : "none" }}
            >
              <input type="hidden" value="@item.ateCodigo" name="ateCodigo" />
              <div className="col s12 no-padding">
                <label htmlFor="observacao">Observações</label>
                <textarea
                  id="observacao"
                  className="browser-default"
                  name="ateObservacao"
                  style={{ height: "120px" }}
                  onChange={(e) => setObservation(e.target.value)}
                ></textarea>
                <span>
                  Arquivos anexados anteriormente: ( {evento.totalAnexo} )
                </span>{" "}
                <br />
                <button
                  className="link no-decoration blue-text"
                  onClick={handleAttachmentClick}
                >
                  Anexar comprovante (ASO)
                </button>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={attachmentInputRef}
                  onChange={handleAttachmentChange}
                />
                <div id="files" className="files">
                  {files.map((file) => (
                    <div className="chip">
                      {file.name}
                      <i
                        className="material-icons close"
                        onClick={handleAttachmentRemove}
                      >
                        close
                      </i>
                    </div>
                  ))}
                </div>
              </div>
              <div className="clearfix"></div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label></label>
                <Checkbox
                  checked={sigiloso}
                  onClick={(e) => setSigiloso(!sigiloso)}
                />
                <p onClick={() => setSigiloso(!sigiloso)}>Arquivo Sigiloso</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-flat f-right"
                  onClick={handleCloseModal}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn waves-effect waves-light m-right-10"
                  onClick={handleSubmit}
                >
                  Ok
                </button>
              </div>
            </form>
            <table role="presentation">
              <tbody className="files"></tbody>
            </table>
          </Fragment>
        ))}
        <div id="codeForm" style={{ display: stage === 0 ? "block" : "none" }}>
          <div className="row">
            <div className="col s12">
              <div className="card-panel">
                <form method="post" id="form_code">
                  <input
                    type="hidden"
                    value={agendaEvento[0].ateCodigo}
                    name="ateCodigo"
                  />
                  <label>Código localizador</label>
                  <input
                    type="text"
                    required
                    maxLength={50}
                    autoComplete="off"
                    onChange={(e) => setLocalizationCode(e.target.value)}
                    value={localizationCode}
                    style={{ textTransform: "uppercase" }}
                  />
                  <button
                    type="submit"
                    className="btn"
                    onClick={handleSubmitLocalizationCode}
                  >
                    Validar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Atendimento };
